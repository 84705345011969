/**
 * Register resource for async load with require-bundle
 */
(function(){

    'use strict';

    
    // Bail if require bundle or server variables not loaded
    if ( ! RequireBundle || ! tattoobonCustomizationsSettings ) return;
    
    var settings = tattoobonCustomizationsSettings,
        ver = tattoobonCustomizationsSettings.assetsVersion;

    RequireBundle.register( 'sticky-states', [ settings.jsLibPath + 'sticky-states' + ver + '.min.js' ], '[data-sticky-states]', function(){ StickyStates.init(); } );

})();
